.logincontainer {
    margin: 32px 16px;
}
.container {
    width: 100%;
}
.orderlistcontainer {
    width: 100%;
}
.paperstyle {
    padding: 16px;
}
.sfield {
    width: 200px;
}
.mfield {
    width: 100%;
}
.lfield {
    width: 100%;
}
.fullfield {
    width: 100%;
}
.uploadedimg {
    width: 80%;
    height: auto;
}


@media (min-width:600px) {
    .container {
        width: 90%;
    }
    .orderlistcontainer {
        width: 90%;
    }
    .paperstyle {
        padding: 32px;
    }
    .mfield {
        width: 300px;
    }
    .lfield {
        width: 500px;
    }
    .uploadimg {
        max-width: 400px;
        height: auto;
    }
}

@media (min-width:900px) {
    .container {
        width: 80%;
        }
}

@media (min-width:1200px) {
    .container {
        width: 60%;
    }
}